// @ts-nocheck
import { ToastContainer, toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import {
  AddTwoTone as AddTwoToneIcon,
  Home,
  Search as SearchIcon
} from '@mui/icons-material';
import React, { useCallback, useState } from 'react';
import APIservice from 'src/utils/APIservice';
import { Card, CardBody, Col, Row } from 'react-bootstrap';
import Loader1 from '../Loader';

function AddApplicationUser() {
  let [ageList, setAgeList] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  let [annualIncomes, setAnnualIncomes] = useState<any[]>([]);
  let [communities, setCommunities] = useState<any[]>([]);
  let [customFields, setCustomFields] = useState<any[]>([]);
  let [diets, setDiets] = useState<any[]>([]);
  let [educations, setEducations] = useState<any[]>([]);
  let [employmentTypes, setEmploymentTypes] = useState<any[]>([]);
  let [heights, setHeights] = useState<any[]>([]);
  let [maritalStatuses, setMaritalStatuses] = useState<any[]>([]);
  let [occupations, setOccupations] = useState<any[]>([]);
  let [profileFor, setProfileFor] = useState<any[]>([]);
  let [religions, setReligions] = useState<any[]>([]);
  let [subCommunities, setSubCommunities] = useState<any[]>([]);
  let [weights, setWeights] = useState<any[]>([]);
  let [documentType, setDocumentTypes] = useState<any[]>([]);
  let [eyeColors, setEyeColors] = useState<any[]>([
    'Amber',
    'Blue',
    'Brown',
    'Gray',
    'Green',
    'Hazel',
    'Red'
  ]);
  let [languages, setLanguages] = useState<any[]>([
    'English',
    'Hindi',
    'Bengali',
    'Marathi',
    'Telugu',
    'Deutsch',
    'Tamil',
    'Gujarati',
    'Kannada',
    'Malayalam',
    'Punjabi',
    'Assamese',
    'Urdu'
  ]);

  let [user, setUser] = useState<any>({
    id: null,
    firstName: '',
    middleName: '',
    lastName: '',
    contactNo: '',
    email: '',
    gender: '',
    password: '',
    confirmPassword: '',
    birthDate: '',
    eyeColor: '',
    languages: '',
    addressLine1: null,
    addressLine2: null,
    pincode: null,
    countryId: null,
    cityId: null,
    stateId: null,
    maritalStatusId: null,
    religionId: null,
    communityId: null,
    occupationId: null,
    educationId: null,
    subCommunityId: null,
    annualIncomeId: null,
    profileForId: null,
    dietId: null,
    heightId: null,
    weightId: null,
    businessName: null,
    companyName: null,
    employmentTypeId: null,
    expectation: null,
    aboutMe: null,
    customFields: []
  });
  const navigate = useNavigate();

  const setUserData = useCallback((key: string, value: string) => {
    setUser({ ...user, [key]: value });
  },[user])
  const saveUserData = useCallback(async (e) => {
    e.preventDefault()
    setIsLoading(true)
    const token = localStorage.getItem('SessionToken');
    const refreshToken = localStorage.getItem('RefreshToken');
    let res = await APIservice.httpPost(
      '/api/admin/users/upsertUser',
      user,
      null,
      null
    );
    setIsLoading(false)
    console.log(res)
    if (res.status != 200) {
      alert(res.message);
    } else {
      alert("User added successfully!!");
      navigate(`/admin/appuser/view/${res?.recordList[0].userId}`);
    }
  }, [user]);

  const loadMasterData = useCallback(async () => {
    setIsLoading(true)
    let res = await APIservice.httpPost(
      '/api/app/users/getMasterData',
      null,
      null
    );
    if (res.status != 200) {
      alert('Something went wrong!!');
    } else {
    setIsLoading(false)
      let data = res.recordList;
      setAgeList(data.ageList);
      setProfileFor(data.profileFor);
      setMaritalStatuses(data.maritalStatus);
      setHeights(data.height);
      setWeights(data.weight);
      setDiets(data.diet);
      setDiets(data.diet);
      setReligions(data.religion);
      setSubCommunities(data.subCommunity);
      setEducations(data.education);
      setOccupations(data.occupation);
      setEmploymentTypes(data.employmentType);
      setAnnualIncomes(data.annualIncome);
      setCustomFields(data.customFields);
      setDocumentTypes(data.documentType);
      setUser({...user, customFields})
    }
  }, []);

  React.useEffect(() => {
    let cred = JSON.parse(localStorage.getItem('Credentials'));
    let isUserProfilePicApprove = JSON.parse(
      localStorage.getItem('isUserProfilePicApprove')
    );
    loadMasterData();
  }, []);

  const setImage = useCallback((key,image) => {
    let reader = new FileReader();
    reader.addEventListener("load", function(evt) {
      setUserData(key,evt.target.result);
    }); 
    reader.readAsDataURL(image)
  },[user])
  return (
    <>
      <ToastContainer
        style={{ top: '10%', left: '80%' }}
        autoClose={6000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Helmet>
        <title>App Users</title>
      </Helmet>
      <PageTitleWrapper>
        <Box pt={1.2} pb={1.1} pl={1}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              {/* <Typography variant="h3" component="h3" gutterBottom>
                  App Users
                </Typography> */}
              <Stack alignItems="left" justifyContent="space-between">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link to="/admin" style={{ display: 'flex', color: 'black' }}>
                    <Home />
                  </Link>
                  <Typography
                    variant="subtitle2"
                    color="inherit"
                    style={{ fontWeight: 'bold' }}
                  >
                    Add User
                  </Typography>
                </Breadcrumbs>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </PageTitleWrapper>
      {isLoading ? (
        <Loader1 title="Loading..." />
      ) : (
        <Container maxWidth="lg">
          <Card className="mb-5">
            <CardBody>
              <h4 className="text-center fw-bold">Add User</h4>
              <form onSubmit={(e)=>saveUserData(e)}>
                <fieldset>
                  <legend>Login Details:</legend>
                  <hr />
                  <Row>
                    <Col md="6">
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                          Email address<span className="text-danger">*</span>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          required
                          value={user.email}
                          onChange={(e) => setUserData('email', e.target.value)}
                          placeholder="Email Address"
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <label htmlFor="contactNo" className="form-label">
                          Contact Number<span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          id="contactNo"
                          required
                          value={user.contactNo}
                          onChange={(e) =>
                            setUserData('contactNo', e.target.value)
                          }
                          placeholder="Contact Number"
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <label htmlFor="password" className="form-label">
                          Password<span className="text-danger">*</span>
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="password"
                          required
                          value={user.password}
                          onChange={(e) =>
                            setUserData('password', e.target.value)
                          }
                          placeholder="Password"
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <label
                          htmlFor="confirm_password"
                          className="form-label"
                        >
                          Confirm Password<span className="text-danger">*</span>
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          id="confirm_password"
                          required
                          value={user.confirmPassword}
                          onChange={(e) =>
                            setUserData('confirmPassword', e.target.value)
                          }
                          placeholder="Confirm Password"
                        />
                      </div>
                    </Col>
                  </Row>
                </fieldset>
                <fieldset>
                  <legend>Step 1:</legend>
                  <hr />
                  <Row>
                    <Col md="6">
                      <div className="mb-3">
                        <label
                          htmlFor="this_profile_is_for"
                          className="form-label"
                        >
                          This profile is for
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          name="this_profile_is_for"
                          id="this_profile_is_for"
                          required
                          value={user.profileForId}
                          onChange={(e) =>
                            setUserData('profileForId', e.target.value)
                          }
                          className="form-control form-select"
                        >
                          <option value="">Select</option>
                          {profileFor.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <label
                          htmlFor="this_profile_is_for"
                          className="form-label"
                        >
                          Photo
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          required
                          onChange={(e) =>
                            setImage("image",e.target.files[0])
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                </fieldset>
                <fieldset>
                  <legend>Step 2: Basic Details</legend>
                  <hr />
                  <Row>
                    <Col md="4">
                      <div className="mb-3">
                        <label htmlFor="firstName" className="form-label">
                          First Name<span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="firstName"
                          required
                          value={user.firstName}
                          onChange={(e) =>
                            setUserData('firstName', e.target.value)
                          }
                          placeholder="First Name"
                        />
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="mb-3">
                        <label htmlFor="middleName" className="form-label">
                          Middle Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="middleName"
                          value={user.middleName}
                          onChange={(e) =>
                            setUserData('middleName', e.target.value)
                          }
                          placeholder="Middle Name"
                        />
                      </div>
                    </Col>
                    <Col md="4">
                      <div className="mb-3">
                        <label htmlFor="lastName" className="form-label">
                          Last Name<span className="text-danger">*</span>
                        </label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          id="lastName"
                          value={user.lastName}
                          onChange={(e) =>
                            setUserData('lastName', e.target.value)
                          }
                          placeholder="Last Name"
                        />
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <label htmlFor="gender" className="form-label">
                          Gender<span className="text-danger">*</span>
                        </label>
                        <select
                          name="gender"
                          id="gender"
                          required
                          className="form-control form-select"
                          value={user.gender}
                          onChange={(e) =>
                            setUserData('gender', e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          {['Male', 'Female', 'Transgender'].map(
                            (item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            )
                          )}
                        </select>
                      </div>
                    </Col>
                    <Col md="6">
                      <div className="mb-3">
                        <label htmlFor="birthDate" className="form-label">
                          Date of Birth<span className="text-danger">*</span>
                        </label>
                        <input
                          type="date"
                          name="birthDate"
                          id="birthDate"
                          value={user.birthDate}
                          onChange={(e) =>
                            setUserData('birthDate', e.target.value)
                          }
                          required
                          className="form-control"
                        />
                      </div>
                    </Col>
                  </Row>
                </fieldset>
                <fieldset>
                  <legend>Step 3: Personal Details</legend>
                  <hr />
                  <Row>
                    <Col md="4" className="mb-3">
                      <label htmlFor="maritalStatus" className="form-label">
                        Marital Status<span className="text-danger">*</span>
                      </label>
                      <select
                        name="maritalStatus"
                        id="maritalStatus"
                        required
                        value={user.maritalStatusId}
                        onChange={(e) =>
                          setUserData('maritalStatusId', e.target.value)
                        }
                        className="form-control form-select"
                      >
                        <option value="">Select</option>
                        {maritalStatuses.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col md="4" className="mb-3">
                      <label htmlFor="height" className="form-label">
                        Select Height<span className="text-danger">*</span>
                      </label>
                      <select
                        name="height"
                        id="height"
                        required
                        value={user.heightId}
                        onChange={(e) =>
                          setUserData('heightId', e.target.value)
                        }
                        className="form-control form-select"
                      >
                        <option value="">Select</option>
                        {heights.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col md="4" className="mb-3">
                      <label htmlFor="weight" className="form-label">
                        Select Weight<span className="text-danger">*</span>
                      </label>
                      <select
                        name="weight"
                        id="weight"
                        required
                        value={user.weightId}
                        onChange={(e) =>
                          setUserData('weightId', e.target.value)
                        }
                        className="form-control form-select"
                      >
                        <option value="">Select</option>
                        {weights.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col md="4" className="mb-3">
                      <label htmlFor="language" className="form-label">
                        Select Language<span className="text-danger">*</span>
                      </label>
                      <select
                        name="language"
                        id="language"
                        required
                        value={user.language}
                        onChange={(e) =>
                          setUserData('language', e.target.value)
                        }
                        className="form-control form-select"
                      >
                        <option value="">Select</option>
                        {languages.map((item, index) => (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col md="4" className="mb-3">
                      <label htmlFor="eyeColor" className="form-label">
                        Select Eye Color<span className="text-danger">*</span>
                      </label>
                      <select
                        name="eyeColor"
                        id="eyeColor"
                        required
                        value={user.eyeColor}
                        onChange={(e) =>
                          setUserData('eyeColor', e.target.value)
                        }
                        className="form-control form-select"
                      >
                        <option value="">Select</option>
                        {eyeColors.map((item, index) => (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </Col>
                  </Row>
                </fieldset>
                <fieldset>
                  <legend>Step 4: Community Details</legend>
                  <hr />
                  <Row>
                    <Col md="6" className="mb-3">
                      <label htmlFor="relegion" className="form-label">
                        Relegion<span className="text-danger">*</span>
                      </label>
                      <select
                        name="relegion"
                        id="relegion"
                        required
                        value={user.religionId}
                        onChange={(e) =>
                          setUserData('religionId', e.target.value)
                        }
                        className="form-control form-select"
                      >
                        <option value="">Select</option>
                        {religions.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col md="6" className="mb-3">
                      <label htmlFor="subCommunity" className="form-label">
                        Sub Community<span className="text-danger">*</span>
                      </label>
                      <select
                        name="subCommunity"
                        id="subCommunity"
                        required
                        value={user.subCommunityId}
                        onChange={(e) =>
                          setUserData('subCommunityId', e.target.value)
                        }
                        className="form-control form-select"
                      >
                        <option value="">Select</option>
                        {subCommunities
                          .filter((item) => item.relegionId == user.relegionId)
                          ?.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                      </select>
                    </Col>
                  </Row>
                </fieldset>
                <fieldset>
                  <legend>Step 6: Education & Career Details</legend>
                  <hr />
                  <Row>
                    <Col md="6" className="mb-3">
                      <label htmlFor="education" className="form-label">
                        Select Education<span className="text-danger">*</span>
                      </label>
                      <select
                        name="education"
                        id="education"
                        required
                        value={user.educationId}
                        onChange={(e) =>
                          setUserData('educationId', e.target.value)
                        }
                        className="form-control form-select"
                      >
                        <option value="">Select</option>
                        {educations.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col md="6" className="mb-3">
                      <label htmlFor="occupation" className="form-label">
                        Occupation<span className="text-danger">*</span>
                      </label>
                      <select
                        name="occupation"
                        id="occupation"
                        required
                        value={user.occupationId}
                        onChange={(e) =>
                          setUserData('occupationId', e.target.value)
                        }
                        className="form-control form-select"
                      >
                        <option value="">Select</option>
                        {occupations.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col md="6" className="mb-3">
                      <label htmlFor="employment" className="form-label">
                        Employment<span className="text-danger">*</span>
                      </label>
                      <select
                        name="employment"
                        id="employment"
                        required
                        value={user.employmentTypeId}
                        onChange={(e) =>
                          setUserData('employmentTypeId', e.target.value)
                        }
                        className="form-control form-select"
                      >
                        <option value="">Select</option>
                        {employmentTypes.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </Col>
                    <Col md="6" className="mb-3">
                      <label htmlFor="annualIncome" className="form-label">
                        Annual Income<span className="text-danger">*</span>
                      </label>
                      <select
                        name="annualIncome"
                        id="annualIncome"
                        required
                        value={user.annualIncomeId}
                        onChange={(e) =>
                          setUserData('annualIncomeId', e.target.value)
                        }
                        className="form-control form-select"
                      >
                        <option value="">Select</option>
                        {annualIncomes.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.value}
                          </option>
                        ))}
                      </select>
                    </Col>
                  </Row>
                </fieldset>
                <fieldset>
                  <legend>Step 6: Additional Details</legend>
                  <hr />
                  <Row>
                    {customFields.map((field, index) =>
                      [1, 2].includes(field.valueTypeId) ? (
                        <Col md="6" className="mb-3" key={index}>
                          <label
                            htmlFor={field.mappedFieldName}
                            className="form-label"
                          >
                            {field.displayName}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type={field.valueTypeId == 1 ? 'text' : 'number'}
                            min={0}
                            name={field.mappedFieldName}
                            className="form-control"
                            onChange={(e) => {
                              customFields[index].value = e.target.value;
                              setUser({...user, customFields});
                            }}
                            placeholder={`Enter ${field.displayName}`}
                          />
                        </Col>
                      ) : (
                        <Col md="6" className="mb-3" key={index}>
                          <label
                            htmlFor={field.mappedFieldName}
                            className="form-label"
                          >
                            {field.displayName}
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            name={field.mappedFieldName}
                            id={field.mappedFieldName}
                            required
                            value={user[field.mappedFieldName]}
                            onChange={(e) =>{
                              customFields[index].value = e.target.value;
                              setUser({...user, customFields});
                            }}
                            className="form-control form-select"
                          >
                            <option value="">Select</option>
                            {field.valueList?.map((item, index) => (
                              <option key={index} value={item}>
                                {item}
                              </option>
                            ))}
                          </select>
                        </Col>
                      )
                    )}
                    <Col md="6" className="mb-3">
                      <label htmlFor="aboutMe" className="form-label">
                        About me<span className="text-danger">*</span>
                      </label>
                      <textarea
                        name="aboutMe"
                        className="form-control"
                        rows={4}
                        value={user.aboutMe}
                        onChange={(e) => setUserData('aboutMe', e.target.value)}
                        placeholder="About me"
                      />
                    </Col>
                    <Col md="6" className="mb-3">
                      <label htmlFor="expectations" className="form-label">
                        Expectations<span className="text-danger">*</span>
                      </label>
                      <textarea
                        name="expectations"
                        className="form-control"
                        placeholder="Expectations"
                        rows={4}
                        value={user.expectation}
                        onChange={(e) =>
                          setUserData('expectation', e.target.value)
                        }
                      />
                    </Col>
                  </Row>
                </fieldset>
                {/* {documentType.length != 0 ? (
                  <fieldset>
                    <legend>Step 7: Documents</legend>
                    <hr />
                    <Row>
                      {documentType.map((document, index) => (
                        <Col md="6" className="mb-3" key={index}>
                          <label htmlFor={document.name} className="form-label">
                            {document.name}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="file"
                            name={document.name}
                            className="form-control"
                            required={document.isRequired}
                          />
                        </Col>
                      ))}
                    </Row>
                  </fieldset>
                ) : null} */}

                <div className="text-center">
                  <button
                    className="btn btn-lg btn-success"
                  >
                    Add
                  </button>
                </div>
              </form>
            </CardBody>
          </Card>
        </Container>
      )}
    </>
  );
}

export default AddApplicationUser;
